<template>
  <div class="weightInfo">
    <base-heading
      type="2"
      modifiers="centerDesktop"
      class="weightInfo__title"
    >
      {{ $t('bodyWeightModal.title') }}
    </base-heading>

    <p class="weightInfo__body weightInfo__body--intro">
      {{ $t('bodyWeightModal.bodyIntro') }}
    </p>

    <p
      class="weightInfo__body"
      v-html-safe="$t('bodyWeightModal.body')"
    />
  </div>
</template>

<script>
import BaseHeading from '@/components/BaseHeading';

export default {
  components: {
    BaseHeading,
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.weightInfo__body {
  @include small;

  @include desktop {
    margin-right: auto;
    margin-left: auto;
  }

  &--intro {
    margin: 0 0 2rem 0;

    @include desktop {
      text-align: center;
    }
  }
}
</style>
